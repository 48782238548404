import React, { useState, useEffect } from 'react';
import { Grid, Typography, TextField, Checkbox, Button, IconButton, CircularProgress, Modal } from '@mui/material';
import axios from 'axios';
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from 'react-i18next';
import '../style/Register.css';
import signUpIcon from '../image/register/signup.png';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import AlertMessage from './modules/AlertMessage';
import { baseUrl, onlyDigit } from "../component/modules/general";
import { NavLink } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { emailValidate, passValidate, mobileNumberValidate, smsValidate } from './modules/validate';
import { setCanonicalTag } from '../component/modules/general';
import { useParams } from 'react-router-dom';
import SmsIcon from '@mui/icons-material/Sms';

const Register = () => {
  const [captcha, setCaptcha] = useState(false);
  const [touched, setTouched] = useState({});
  const [errors, setErrors] = useState({});
  const [email, setEmail] = useState("");
  const [alert, setAlert] = useState({ alertCheck: false, status: null, message: "", severity: "" });
  const [pass, setPass] = useState("");
  const [referalCode, setReferalCode] = useState("");
  const [showPass, setShowPass] = useState(false);
  const [roulsCheck, setRoulscheck] = useState(false);
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [registerBtn, setRegisterBtn] = useState(false);
  const [textFieldSize, setTextFiledSize] = useState(window.innerWidth <= 900 ? "small" : "medium");
  const [mobile, setMobile] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [sms, setSms] = useState("");
  const [sendSms, setSendSms] = useState(false);
  const [startTimer, setStartTimer] = useState(false);
  const [timeLeft, setTimeLeft] = useState(0);
  const [otpCheckDisable, setOtpCheckDisable] = useState(true);
  const params = useParams();
  const Navigate = useNavigate();

  useEffect(() => {
    document.title = "ثبت نام در بهترین صرافی ارز دیجیتال ایران | ساخت حساب کریپتوکامرس"
    document.getElementById("main-heading").innerHTML = "ثبت نام سریع در کریپتوکامرس ؛ بزرگترین صرافی ارز دیجیتال ایرانی";
    document.getElementsByTagName('meta')["description"].content = "شما در حال ثبت نام در کریپتوکامرس ؛ بزرگترین صرافی ارز دیجیتال ایران هستید. ثبت نام سریع در بزرگترین بازار رمزارز ایران";
    document.getElementsByTagName('meta')["keywords"].content = "ثبت نام سریع در صرافی ارز دیجیتال | ساخت حساب در امن ترین صرافی ایران | ثبت نام در معتبرترین صرافی ایران | ثبت نام کاربر در بهترین صرافی ایرانی | ثبت نام در کریپتوکامرس | ثبت پنل کاربری کریپتوکامرس | ثبت نام آنی صرافی کریپتوکامرس | شروع به کار در کریپتوکامرس"
    document.getElementsByTagName('meta')["author"].content = "شرکت وب گستران سورین";
    setCanonicalTag()
  }, []);

  useEffect(() => {
    window.addEventListener('resize', () => resizeTextFiled());
    return (() => window.removeEventListener('resize', () => resizeTextFiled()));
  });

  useEffect(() => {
    params.length !== 0 && params.hasOwnProperty("value") && setReferalCode(params.value)
  }, [params])

  //send otp
  useEffect(() => {
    if (!timeLeft) return;
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);
    return () => {
      clearInterval(intervalId)
    };
  }, [timeLeft, startTimer]);

  useEffect(() => {
    if (timeLeft === 0) {
      setSendSms(false)
    }
  }, [timeLeft]);

  const resizeTextFiled = () => {
    if (window.innerWidth < 900 && textFieldSize === 'medium') {
      setTextFiledSize('small')
    }
    else if (window.innerWidth > 900 && textFieldSize === 'small') {
      setTextFiledSize('medium')
    }
  }
  //oncahange textField
  const emailHandeler = (event) => {
    setErrors(emailValidate(event.target.value, t))
    setEmail(event.target.value);
  }
  const passHandeler = (event) => {
    setPass(event.target.value);
    setErrors(passValidate(event.target.value, t))
  }
  const mobileHandler = (event) => {
    setErrors(mobileNumberValidate(event.target.value, t));
    setMobile(event.target.value);
  }

  //show pass
  const handleClickShowPass = () => setShowPass((show) => !show);
  const handleMouseDownPass = (event) => {
    event.preventDefault();
  }
  //focus in textFiled
  const focusHandler = (event) => {
    setTouched({ ...touched, [event.target.name]: true })
    if (event.target.name === "mobile") { setMobile("09") }
  }
  //onChange Recaptcha
  const onChangeRechapta = (value) => {
    value != null ? setCaptcha(true) : setCaptcha(false)
  }
  //post data ti Api
  const registerData = () => {
    let data;
    let referal_code = referalCode;
    referalCode.length > 0 ? data = { email, pass, mobile, referal_code } : data = { email, pass, mobile };
    Promise.resolve(axios({
      url: baseUrl + '/api/v4/user/register',
      method: "POST",
      data: data
    }))
      .then((res) => {
        // setRegisterBtn(false);
        setLoading(false);
        res.data.data === false && referalCode.length > 0 ? setAlert({ alertCheck: true, message: t("AM_success_nullCode"), severity: "warning" }) :
          setAlert({ alertCheck: true, message: t("AM_success"), severity: "success" })
        const timeout = setTimeout(() => {
          Navigate('/Login');
        }, 3000)
        return () => {
          clearTimeout(timeout)
        };
      })
      .catch(
        (error) => {
          // setRegisterBtn(false);
          setLoading(false);
          if (error.code === "ERR_NETWORK") {
            setAlert({ alertCheck: true, message: t("networkNotconnected"), severity: "error" })
          }
          else {
            const { status } = error.response;
            setAlert({ alertCheck: true, status: status, message: "", severity: "error" })
          }
        }
      )
  }
  //submit Button SignUp
  const submitHandler = () => {
    if ((email === "") || (pass === "") || (mobile === "")) {
      setAlert({ alertCheck: true, message: t("AM_fieldNull"), severity: "error" })
    }
    else if (Object.keys(errors).length) {
      setAlert({ alertCheck: true, message: t("AM_fieldError"), severity: "error" })
    }
    else if (roulsCheck === false) {
      setAlert({ alertCheck: true, message: t("AM_roulsCheck"), severity: "error" })
    }
    else if (captcha === false) {
      setAlert({ alertCheck: true, message: t("AM_captcha"), severity: "error" })
    }
    else if ((!Object.keys(errors).length) && (captcha) && (roulsCheck === true)) {
      setRegisterBtn(true);
      // setLoading(true);
      // registerData();
      setOpenModal(true);
    }
  }
  //landle close Alert
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    return setAlert({ alertCheck: false, message: "", severity: alert.severity })
  };

  //add otp
  const closeModal = () => {
    setOpenModal(false);
    setSms('');
    setRegisterBtn(false)
  }

  const format = (timeLeft) => {
    const date = new Date(timeLeft * 1000);
    let hh = date.getUTCHours();
    let mm = date.getUTCMinutes();
    let ss = date.getSeconds();
    if (hh < 10) { hh = "0" + hh; }
    if (mm < 10) { mm = "0" + mm; }
    if (ss < 10) { ss = "0" + ss; }
    return '00' !== hh ? hh + ":" + mm + ":" + ss : mm + ":" + ss;
  }

  const smsHandler = (event) => {
    setErrors(smsValidate(event.target.value, t));
    setSms(event.target.value);
    if (event.target.value.length === 6) {
      setOtpCheckDisable(false);
    }
    else {
      setOtpCheckDisable(true);
    }
  }

  const sendSmsHandler = () => {
    setLoading(true)
    Promise.resolve(axios({
      url: baseUrl + '/api/v4/otp/sendMessage',
      method: "POST",
      data: { mobile: mobile },
    }))
      .then((res) => {
        setLoading(false)
        setTimeLeft(res.data.data)
        setStartTimer(true)
        setSendSms(true);
      })
      .catch((error) => {
        if (error.code === "ERR_NETWORK") {
          setAlert({ alertCheck: true, message: t("networkNotconnected"), severity: "error" })
        }
        else {
          const { status } = error.response;
          setAlert({ alertCheck: true, status: status, message: "", severity: "error" })
        }
        setLoading(false);
      })
  }

  const getOtpCheck = () => {
    setOtpCheckDisable(true);
    setLoading(true);
    Promise.resolve(axios({
      url: baseUrl + '/api/v4/otp/checkOtpSite',
      method: "GET",
      params: {
        otp: sms,
        mobile: mobile
      },
    }))
      .then((res) => {
        setLoading(false)
        if (res.data.data === true) {
          registerData()
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.code === "ERR_NETWORK") {
          setAlert({ alertCheck: true, message: t("networkNotconnected"), severity: "error" })
        }
        else {
          const { status } = error.response;
          setAlert({ alertCheck: true, status: status, message: "", severity: "error" })
        }
      })
      .finally(() => {
        setOtpCheckDisable(false);
      });
  }

  return (
    <>
      <Grid sx={{
        direction: i18n.language === "fa" ? "rtl" : "ltr", backgroundColor: "background.main", width: "100%", height: "100%",
        display: "flex", flexDirection: { xs: "column", sm: "row" }, alignItems: "center", padding: { xs: "160px 0", sm: "260px 0", md: "180px 0" }, justifyContent: "center"
      }}
      >
        <Grid sx={{ width: { xs: "100%", sm: "50%" }, height: "100%", display: "flex", justifyContent: { xs: "center", md: "flex-end" } }}>
          <Grid sx={{
            backgroundColor: "background.secondary", maxWidth: { xs: "95%", sm: "306px", md: "478px", lg: "478px" }, minWidth: { xs: "95%", sm: "280px", md: "390px", lg: "478px" },
            maxHeight: { sm: "452px", md: "706px", lg: "706px" }, minHeight: { sm: "450px", md: "670px", lg: "706px" }, margin: "0 4%", padding: "3% 0",
            width: "100%", height: "100%", borderRadius: { xs: "45px", sm: "49px", md: "78px" }, boxShadow: " 1px 3px 6px rgba(0, 0, 0, 0.25)", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"
          }}>
            <Grid sx={{ display: "flex", flexDirection: "row", paddingBottom: { sm: "10px", md: "15px" }, justifyContent: "center", alignItems: "center", paddingTop: { xs: "3%", sm: "" } }}>
              <Typography sx={{ fontSize: i18n.language === "fa" ? { xs: "14px", sm: "16px", md: "25px" } : { xs: "17px", md: "15px", lg: "20px" }, color: "text.primary" }}>
                {t("titleRegister")}
              </Typography>
              <Typography sx={{
                fontSize: i18n.language === "fa" ? { xs: "14px", sm: "16px", md: "25px" } : { xs: "17px", md: "15px", lg: "20px" },
                color: "text.greenDark", lineHeight: "30px", fontWeight: "400", margin: i18n.language === "fa" ? "0 5px 0 0" : "0 0 0 5px"
              }}>
                {t("mcc")}
              </Typography>
            </Grid>
            <Grid sx={{ margin: { xs: "3% 0 2%", sm: "10px 0 5px", md: "3% 0px 4%" }, display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }} >
              <TextField
                className="textField"
                fullWidth
                label={t("email")}
                name="email"
                // inputMode="email"
                type="email"
                value={email}
                onChange={event => emailHandeler(event)}
                onFocus={focusHandler}
                error={errors.email && touched.email}
                helperText={errors.email && touched.email && errors.email}
                InputLabelProps={{ sx: { fontSize: { xs: "14px", md: "1rem" } } }}
                variant="outlined"
                size={textFieldSize}
                sx={{
                  "& .MuiOutlinedInput-root": { "&.Mui-focused fieldset": { borderColor: "#D9D9D9" } }, width: "100%",
                  "& .MuiInputBase-root fieldset": { borderRadius: "16px" }, minWidth: { xs: "70%", sm: "200px", md: "300px" }, maxWidth: { xs: "80%", sm: "230px", md: "313px" }
                }}
              />
            </Grid>
            <Grid sx={{ margin: { xs: "2% 0", sm: "5px 0", md: "2% 0px 4%" }, display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }} >
              <TextField
                className="textField"
                fullWidth
                type={showPass ? 'text' : 'password'}
                name="pass"
                value={pass}
                onChange={event => passHandeler(event)}
                label={t("password")}
                InputLabelProps={{ sx: { fontSize: { xs: "14px", md: "1rem" } } }}
                variant="outlined"
                size={textFieldSize}
                onFocus={focusHandler}
                error={errors.pass && touched.pass}
                helperText={errors.pass && touched.pass && errors.pass}
                sx={{
                  "& .MuiOutlinedInput-root": { "&.Mui-focused fieldset": { borderColor: "#D9D9D9" } },
                  "& .MuiInputBase-root fieldset": { borderRadius: "16px" }, minWidth: { xs: "70%", sm: "200px", md: "300px" }, maxWidth: { xs: "80%", sm: "230px", md: "313px" }
                }}
                InputProps={{
                  endAdornment:
                    <IconButton
                      aria-label="toggle pass visibility"
                      onClick={handleClickShowPass}
                      onMouseDown={handleMouseDownPass}
                      edge="end"
                    >
                      {showPass ? <VisibilityIcon sx={{ fontSize: { sm: "18px", md: "24px" } }} /> : <VisibilityOffIcon sx={{ fontSize: { sm: "18px", md: "24px" } }} />}
                    </IconButton>
                }}
              />
            </Grid>
            <Grid sx={{ margin: { xs: "2% 0", sm: "5px 0", md: "2% 0px 4%" }, display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }} >
              <TextField
                className="textField"
                fullWidth
                name='mobile'
                value={onlyDigit(mobile)}
                onChange={event => mobileHandler(event)}
                onFocus={focusHandler}
                inputProps={{ maxLength: 11 }}
                InputLabelProps={{ sx: { fontSize: { xs: "14px", md: "1rem" } } }}
                error={errors.mobile && touched.mobile}
                helperText={errors.mobile && touched.mobile && errors.mobile}
                label={t("mobileNumber")}
                variant="outlined"
                size={textFieldSize}
                sx={{
                  "& .MuiOutlinedInput-root": { "&.Mui-focused fieldset": { borderColor: "#D9D9D9" } },
                  "& .MuiInputBase-root fieldset": { borderRadius: "16px" }, minWidth: { xs: "70%", sm: "200px", md: "300px" }, maxWidth: { xs: "80%", sm: "230px", md: "313px" }
                }}
              />
            </Grid>
            <Grid sx={{ margin: { xs: "2% 0", sm: "5px 0", md: "2% 0px 4%" }, display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }} >
              <TextField
                className="textField"
                fullWidth
                name='referalCode'
                disabled={params.length !== 0 && params.hasOwnProperty("value") ? true : null}
                value={(referalCode).toUpperCase()}
                onChange={event => setReferalCode(event.target.value)}
                InputLabelProps={{ sx: { fontSize: { xs: "14px", md: "1rem" } } }}
                label={t("referal code")}
                variant="outlined"
                size={textFieldSize}
                sx={{
                  "& .MuiOutlinedInput-root": { "&.Mui-focused fieldset": { borderColor: "#D9D9D9" } },
                  "& .MuiInputBase-root fieldset": { borderRadius: "16px" }, minWidth: { xs: "70%", sm: "200px", md: "300px" }, maxWidth: { xs: "80%", sm: "230px", md: "313px" }
                }}
              />
            </Grid>
            <Grid sx={{
              display: "flex", flexDirection: "row", alignItems: "center", minWidth: { xs: "70%", sm: "200px", md: "300px" }, maxWidth: { xs: "80%", sm: "230px", md: "313px" }, width: "100%",
              padding: { sm: "0 10px", md: "0 5px 10px", lg: "0 5px 15px" }
            }}>
              <Checkbox
                sx={{ '&.Mui-checked': { color: "text.greenLight" }, '& .MuiSvgIcon-root': { fontSize: "20px" } }}
                name="roulsCheck"
                checked={roulsCheck}
                onChange={event => setRoulscheck(event.target.checked)}
                onFocus={focusHandler}
                required
              />
              <Typography sx={{
                margin: i18n.language === "fa" ? "0 0 0 3px" : "0 3px 0 0", fontSize: i18n.language === "fa" ?
                  { xs: "9px", md: "14px" } : { xs: "9px", sm: "12px", md: "9px", lg: "11px" }, lineHeight: "16px", color: "text.primary"
              }}>{t("labelRoulsPart1")}
              </Typography>
              <Typography sx={{
                margin: i18n.language === "fa" ? "0 0 0 3px" : "0 3px 0 0", fontSize: i18n.language === "fa" ?
                  { xs: "9px", md: "14px" } : { xs: "9px", sm: "12px", md: "9px", lg: "11px" }, borderBottom: "1px solid #0C5463", color: "text.greenDark"
              }}>
                {t("labelRoulsPart2")}
              </Typography>
              <Typography sx={{
                fontSize: i18n.language === "fa" ? { xs: "9px", md: "14px" } : { xs: "9px", sm: "12px", md: "9px", lg: "11px" }, lineHeight: "16px", color: "text.primary"
              }}>
                {t("labelRoulsPart3")}
              </Typography>
            </Grid>
            <Grid sx={{ display: "flex", justifyContent: "center", margin: "2% 0" }} className="g-recaptcha-inner">
              <ReCAPTCHA
                sitekey="6Le7R9UUAAAAAFklnUhPVV2qiSfeLXJHpDgnv6V3"
                onChange={(value) => { onChangeRechapta(value) }}
                className="g-recaptcha-login"
                hl="fa"
              />
            </Grid>
            <Grid sx={{ margin: { xs: "2% 0", md: "5% 0" }, display: "flex", alignItems: "center", justifyContent: "center", width: "100%" }}>
              <Button sx={{
                width: { xs: "50%", sm: "173px", md: "270px" }, height: { xs: "41px", md: "63px" }, background: (theme) => theme.palette.button.primary, borderRadius: "40px !important",
                "&:hover": { background: (theme) => theme.palette.button.primaryHover }, boxShadow: "1px 2px 3px rgba(0, 0, 0, 0.3)"
              }}
                onClick={submitHandler}
                disabled={registerBtn}
              >
                <Typography sx={{ fontSize: { xs: "11px", sm: "13px", md: "20px" }, color: "#FCFCFC", lineHeight: "18px", padding: { xs: "5px", sm: "10px" } }}>
                  {/* {registerBtn === true && loading === true ? <CircularProgress size={20} color="inherit" /> : t("signUp")} */}
                  {t("signUp")}
                </Typography>
              </Button>
            </Grid>
            <Grid sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", margin: { xs: "2% 0", md: "1% 0", lg: "0 0" } }}>
              <Typography sx={{ fontSize: i18n.language === "fa" ? { xs: "10px", md: "16px" } : { xs: "13px", sm: "11px" }, margin: i18n.language === "fa" ? "0 0 0 5px" : "0 5px 0 0" }}>{t("registered")}</Typography>
              <NavLink to="/Login" className="loginNav">
                <Typography sx={{ fontSize: i18n.language === "fa" ? { xs: "10px", md: "16px" } : { xs: "13px", sm: "11px" }, color: "text.greenDark", borderBottom: "1px solid #0C5463" }}>{t("signIn")}</Typography>
              </NavLink>
            </Grid>
          </Grid>
        </Grid>
        <Grid sx={{ width: { xs: "100%", sm: "50%" }, display: "flex", justifyContent: { xs: "center", md: "start" }, margin: { xs: "0px", md: "0px 30px" } }}>
          <Grid sx={{
            width: { xs: "100%", sm: "98%", md: "95%", lg: "80%" }, display: "flex", padding: { xs: "30px 0px", sm: "0px 2%", md: "20px 5%", lg: "30px" },
            flexDirection: "column", alignItems: "center"
          }}>
            <Grid sx={{ width: "100%", display: { xs: "none", sm: "flex" }, alignItems: "center", justifyContent: "center" }}>
              <Typography component={"img"} alt="signup_Icon" src={signUpIcon}
                sx={{ maxWidth: { sm: "278px", md: "442px" }, maxHeight: { sm: "278px", md: "442px" }, minWidth: { sm: "220px", md: "400px" }, minHeight: { sm: "220px", md: "400px" } }} />
            </Grid>
            <Grid sx={{
              display: "flex", flexDirection: "column", alignItems: "start", minWidth: { xs: "95%", sm: "220px", md: "360px" }, maxWidth: { xs: "95%", sm: "328px", md: "512px" },
              borderRadius: { xs: "18px", sm: "25px", md: "35px" }, maxHeight: { xs: "160px", sm: "174px", md: "272px" }, backgroundColor: "background.secondary", boxShadow: ' 1px 3px 6px rgba(0, 0, 0, 0.25)',
              padding: { xs: i18n.language === "fa" ? "20px 10px 20px 20px" : "20px 20px 10px 20px", sm: i18n.language === "fa" ? "30px 20px 30px 30px" : "20px 20px 20px 10px" }
            }}>
              <Grid sx={{ display: "flex", flexDirection: "row", alignItems: "center", marginBottom: "15px" }}>
                <Checkbox disabled checked sx={{ '&.Mui-checked': { color: "text.greenLight" }, '& .MuiSvgIcon-root': { fontSize: "20px" } }} />
                <Typography sx={{ fontSize: i18n.language === "fa" ? { xs: "10px", sm: "11px", md: "16px" } : { xs: "10px", sm: "11px", md: "14px", lg: "16px" }, textAlign: "justify", color: "text.primary" }} >{t("roulsPart1")}</Typography>
              </Grid>
              <Grid sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                <Checkbox disabled checked sx={{ '&.Mui-checked': { color: "text.greenLight" }, '& .MuiSvgIcon-root': { fontSize: "20px" } }} />
                <Typography sx={{ fontSize: i18n.language === "fa" ? { xs: "10px", sm: "11px", md: "16px" } : { xs: "10px", sm: "11px", md: "14px", lg: "16px" }, textAlign: "justify", color: "text.primary" }}>{t("roulsPart2")}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {openModal &&
          <Modal
            open={openModal} onClose={closeModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Grid sx={{ display: 'flex', alignItems: "center", justifyContent: 'center', width: "100vw", height: "100vh", backgroundColor: 'rgba(105,99,99,0.6)' }}>
              <Grid sx={{
                backgroundColor: "background.main", boxShadow: " 1px 3px 6px rgba(0, 0, 0, 0.25)", borderRadius: { xs: "28px", sm: "48px", md: "60px" }, padding: "1% 0", width: "100%", margin: "0 5%",
                maxWidth: "570px", display: "flex", alignItems: 'center', justifyContent: 'center', flexDirection: "column"
              }}>
                <Grid sx={{ width: "100%", display: 'flex', alignItems: 'center', justifyContent: "center", padding: "2% 0", flexDirection: "column" }}>
                  <TextField
                    label={t("sms")}
                    variant='outlined'
                    fullWidth
                    sx={{
                      "& .MuiOutlinedInput-root": { "&.Mui-focused fieldset": { borderColor: "#D9D9D9" } }, "& .MuiInputBase-root fieldset": { borderRadius: "16px" },
                      width: { xs: "80%", sm: "60%" }
                    }}
                    className="textField"
                    name="sms"
                    size={textFieldSize}
                    value={onlyDigit(sms)}
                    onChange={event => smsHandler(event)}
                    onFocus={focusHandler}
                    InputLabelProps={{ sx: { fontSize: { xs: "12px", md: "1rem" } } }}
                    error={errors.sms && touched.sms}
                    helperText={errors.sms && touched.sms && errors.sms}
                    inputProps={{ maxLength: 6 }}
                    InputProps={{
                      endAdornment:
                        <Button
                          onClick={sendSmsHandler}
                          disabled={sendSms === true ? true : false}
                          endIcon={<SmsIcon
                            sx={{ display: sendSms === false && loading === false ? "block" : "none", marginRight: i18n.language === "fa" ? "5px" : "0px", fontSize: { xs: "10px", sm: "15px" } }} />}
                          sx={{
                            fontSize: { xs: "10px", sm: "12px", md: "14px" },
                            width: "120px", textAlign: "center", color: "text.white", marginLeft: "5px",
                            transition: "all 0.1s ease-in-out", backgroundColor: "text.greenLight", borderRadius: "10px", "&:hover": { backgroundColor: "text.greenLight" }, "& .MuiButton-endIcon": { marginLeft: 0 }
                          }}
                        >
                          {loading === false ? sendSms === false ? t("send") : format(timeLeft) : <CircularProgress size={20} color="inherit" sx={{ color: "text.white" }} />}
                        </Button>
                    }}
                  />
                  <Typography sx={{ fontSize: i18n.language === "fa" ? { xs: "10px", md: "13px" } : { xs: "10px", sm: "12px" }, textAlign: "center", padding: "1% 8%", textJustify: "inter-word", color: "text.primary" }}>
                    {sendSms === false ? t("smsCodeSend") : t("smsCodeTitle")}
                  </Typography>
                </Grid>
                <Grid sx={{ margin: { xs: "2% 0", md: "5% 0" }, display: sendSms === false ? "none" : "flex", alignItems: "center", justifyContent: "center", width: "100%" }}>
                  <Button
                    onClick={getOtpCheck}
                    disabled={otpCheckDisable}
                    sx={{
                      width: { xs: "50%", sm: "173px", md: "270px" }, height: { xs: "41px", md: "63px" }, background: (theme) => theme.palette.button.primary, borderRadius: "40px !important",
                      "&:hover": { background: (theme) => theme.palette.button.primaryHover }, boxShadow: "1px 2px 3px rgba(0, 0, 0, 0.3)"
                    }}
                  >
                    <Typography sx={{ fontSize: { xs: "11px", sm: "13px", md: "20px" }, color: otpCheckDisable ? "#9ea19f" : "#FCFCFC", lineHeight: "18px", padding: { xs: "5px", sm: "10px" } }}>
                      {/* {registerBtn === true && loading === true ? <CircularProgress size={20} color="inherit" /> : t("signUp")} */}
                      {t("signUp")}
                    </Typography>
                  </Button>
                </Grid>
                <Grid sx={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", padding: "2% 0 0" }}>
                  <Typography onClick={closeModal} sx={{ paddingTop: "10px", cursor: "pointer" }}>{t("close")}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Modal >
        }
        <Grid sx={{ display: "none" }}>
          <Typography sx={{ display: "none" }}>
            برای ورود به بهترین صرافی ایران کافیست ابتدا ثبت نام کنید. برای ثبت نام در معتبرترین صرافی ارز دیجیتال ایران به یک ایمیل معتبر نیاز دارید. این ایمیل باید برای سایت گوگل یا یاهو باشد. درغیر اینصورت امکان ثبت نام وجود ندارد. پس برای ثبت نام در امن ترین و بزرگترین صرافی ارز دیجیتال ایرانی یعنی کریپتوکامرس باید دارای یک ادرس ایمیل متعلق به شرکت گوگل و یا یاهو باشید. درصورت نداشتن ایمیل معتبر گوگل یا یاهو لطفا به سایت های مذکور رفته و ایمیل ادرس برای خود بسازید.
          </Typography>
          <Typography sx={{ display: "none" }}>
            پس از ساخت ایمیل و چک کردن اینباکس خود و اینکه مطمئن باشید به پیام های ارسالی دسترسی دارید و میتوانید پیام ارسال کنید. سپس می توانید در کریپتوکامرس ؛ بزرگترین صرافی ارز دیجیتال ایران شروع به کار کنید. پس از ثبت ایمیل معتبر و انتخاب گذر واژه امن ( شامل عدد، حروف بزرگ و کوچک و حتی علامت ) و ورود دوباره آن نیاز به زدن تیک من ربات نیستم می باشد. پس از ثبت موارد گفته شده، ایمیل حاوی متن خوش آمد گویی و لینک تاییدیه می باشد. با ضربه زدن رو لینک تایید ایمیل، شما در سامانه صرافی ارز دیجیتال کریپتوکامرس به عنوان کاربر تایید می شوید. شما هم اکنون یکی از کاربران معتبرترین صرافی ارز دیجیتال ایرانی یعنی کریپتوکامرس هستید.
          </Typography>
          <Typography sx={{ display: "none" }}>
            برای کار در صرافی بزرگ کریپتوکامرس ابتدا نیاز است تا احراز هویت خود را به صورت کامل انجام دهید. احراز هویت در کمتر از یک دقیقه و بسیار راحت صورت می پذیرد. احراز هویت کریپتوکامرس شامل یک پیامک به صاحب دارنده شماره همراه وارد شده است. سپس اطلاعات هویتی خود را وارد کنید تا با سازمان ثبت احوال چک شود. درصورت تایید سازمان ثبت احوال وارد مرحله بعدی خواهید شد. در اینجا نیاز است با خط خود متنی را در صفحه سفید نوشته امضا و انگشت بزنید. متن پیام چنین است : اینجانب ساتوشی ناکاموتو به شماره ملی 0079904653 متعهد می شود که فقط برای خودم خرید و فروش ارز دیجیتال را انجام دهم و اطلاعات حسابم را در اختیار غیر قرار ندهم. از منبع شخصی خود وجه را به صرافی کریپتوکامرس واریز کنم و مسئولیت قضایی حقوقی و انتظامی وجوه خود را میپذیرم. درصورتیکه خلاف قوانین عمل کنم از رای کریپتوکامرس تبعیت کرده و خسارات ناشی را متقبل می شوم. با ارسال این سلفی تمام مسئولیت اکانت متوجه شماست. به جز اینکه باید در حفظ اطلاعات کوشا باشید، حتما اطلاعات خود را در زمینه انواع کلاهبرداری هم به روز کنید.
          </Typography>
          <Typography sx={{ display: "none" }}>
            افراد زیادی روزانه در سطح اینترنت و شبکه های اجتماعی در حال ترغیب کاربران به ثبت نام در صرافی کریپتوکامرس هستند. این افراد بعد از راهنمایی شما به ثبت نام، وجه دزدی و کثیف را به حساب شما واریز می کنند. سپس از شما درخواست می کنند تا در صرافی کریپتوکامرس اقدام به خرید ارز دیجیتال کنید. بعد از خرید باید مقداری را به فرد کلاهبردار انتقال دهید و مبلغی ناچیز هم به عنوان سود کار به شما تعلق می گیرد. اتفاق اصلی اینجا رقم میخورد : پلیس فتا با مدیران صرافی ارز دیجیتال کریپتوکامرس ارتباط می گیرد و اطلاعات کاربر فریب خورده را درخواست می کند. کریپتوکامرس که تاکید کرده بود برای کسی غیر از خودتان رمزارز تهیه نکنید، مدارک را در اختیار پلیس فتا قرار می دهد. فریب خورده علاوه بر پرداخت خسارت به کریپتوکامرس به حبس تعزیری هم محکوم می شود. برای کار در صرافی کریپتوکامرس نظیر : واریز و برداشت ریال، واریز و برداشت رمزارز، ترید و نوسان گیری از 400 ارز دیجیتال، درآمدزایی و وام دهی کریپتوکامرس، سفارش گذاری پیشرفته و .... نیاز است ابتدا امنیت اکانت خود را افزایش دهید.
          </Typography>
          <Typography sx={{ display: "none" }}>
            دو مرحله امنیتی در صرافی کریپتوکامرس تعریف شده است. سطح اول امنیتی صرافی امن کریپتوکامرس فعال سازی اکانت در اپلیکیشن گوگل اتنتیکیتور می باشد. اپلیکیشن رمز موقت ساز گوگل به عنوان امن ترین اپلیکیشن های رمزساز دنیاست. مرحله دوم امنیتی در صرافی ارز دیجیتال کریپتوکامرس فعالسازی پیامک می باشد. با فعالسازی پیامک، ورود و برداشت های شما با ارسال و ثبت پیامک امکان پذیر است. با فعال سازی هردو سطح امنیتی گوگل اتنتیکیتور و پیامک، پروفایل حساب کاربری خود در صرافی کریپتوکامرس را کاملا امن سازید. برای ورود امن به صرافی کریپتوکامرس پیشنهاد می کنیم هر دو سطح امنیتی را فعال کنید تا احتمال به خطر افتادن اکانت شما به صفر برسد.
          </Typography>
          <Typography sx={{ display: "none" }}>
            برای ورود به صرافی کریپتوکامرس داشتن حداقلی اطلاعات در مورد بازار ارز دیجیتال ضروریست. با ورود به صرافی کریپتوکامرس به بزرگترین بازار ارز دیجیتال ایران بپیوندید. برای ثبت نام و احراز هویت در صرافی کریپتوکامرس حداقل سن 18 سال و داشتن تبعه و ملیت ایرانی ضروریست. اتباع بیگانه اجازه ورود و کار در صرافی کریپتوکامرس را ندارند. به بزرگترین صرافی ارز دیجیتال ایرانی با بیش از 400 رمزارز معروف بازار خوش آمدید. کریپتوکامرس مقدم کاربران عزیز را گرامی می دارد. ثبت نام سریع در بزرگترین بازار رمزارز ایران به معنی کوتاهترین مرحله برای ورود امن شما به بزرگترین بازار ارز دیجیتال داخلی است.
          </Typography>
        </Grid>
      </Grid>
      <AlertMessage open={alert.alertCheck} status={alert.status} message={alert.message} duration={6000} close={handleClose} severity={alert.severity} page={"Register"} />
    </>
  );
};

export default Register;
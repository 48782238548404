import React, { useState, useEffect } from "react";
import { Grid, Typography, Button, Checkbox, IconButton, TextField, CircularProgress } from "@mui/material";
import { useTranslation } from 'react-i18next';
import { passValidate, repeatPassValidate, emailValidate, smsValidate } from "./modules/validate";
import AlertMessage from "./modules/AlertMessage";
import axios from "axios";
import signinIcon from '../image/register/signin.png';
import { baseUrl, MainEmail, onlyDigit } from "./modules/general";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useParams, useNavigate } from "react-router-dom";
import ModalPass from "./modules/ModalPass";
import { setCanonicalTag } from "./modules/general";
import { useLocation } from 'react-router-dom';
import ReCAPTCHA from "react-google-recaptcha";
import SmsIcon from '@mui/icons-material/Sms';

const ChangePass = () => {
  const params = useParams();
  const Navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [pass, setPass] = useState("");
  const [errors, setErrors] = useState({});
  const [repeatPass, setRepeatPass] = useState("")
  const [touched, setTouched] = useState({});
  const [alert, setAlert] = useState({ alertCheck: false, status: null, message: "", severity: "" });
  const [showPass, setShowPass] = useState(false);
  const [info, setInfo] = useState({});
  // const [open, setOpen] = useState(false);
  const [security, setSecurity] = useState(0);
  // const [data, setData] = useState({ secturity: "", mobile: "", email: "", pass: "" });
  const [loading, setLoading] = useState(false);
  const [resetBtn, setResetBtn] = useState(false);
  const [textFieldSize, setTextFiledSize] = useState(window.innerWidth <= 900 ? "small" : "medium");
  const [email, setEmail] = useState(params.email);
  const location = useLocation();
  const ttl = location.state?.ttl || null;
  const [sms, setSms] = useState("");
  const [timeLeft, setTimeLeft] = useState(ttl || 0);
  const [captcha, setCaptcha] = useState(false);

  useEffect(() => {
    document.title = "خرید و فروش رمزارز | کریپتوکامرس"
    document.getElementById("main-heading").innerHTML = "خرید - فروش -رمزارز- کریپتوکامرس";
    document.getElementsByTagName('meta')["description"].content = "";
    document.getElementsByTagName('meta')["author"].content = "شرکت وب گستران سورین";
    setCanonicalTag()
  }, []);

  useEffect(() => {
    window.addEventListener('resize', () => resizeTextFiled());
    return (() => window.removeEventListener('resize', () => resizeTextFiled()));
  });

  //onChange Recaptcha
  const onChangeRechapta = (value) => {
    value != null ? setCaptcha(true) : setCaptcha(false)
  }

  useEffect(() => {
    Promise.resolve(axios({
      url: baseUrl + "/api/v4/user/get?email=" + params.email,
      method: "GET",
    }))
      .then((res) => {
        setInfo(res.data.data);
        setSecurity(res.data.data.security_id)
      })
  }, [])

  useEffect(() => {
    if (ttl) {
      setTimeLeft(ttl)
    }
  }, [ttl])

  useEffect(() => {
    // if (!timeLeft) return;
    if (timeLeft <= 0) {
      Navigate('/Login');
      return;
    }
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);
    return () => {
      clearInterval(intervalId)
    };
  }, [timeLeft]);

  const resizeTextFiled = () => {
    if (window.innerWidth < 900 && textFieldSize === 'medium') {
      setTextFiledSize('small')
    }
    else if (window.innerWidth > 900 && textFieldSize === 'small') {
      setTextFiledSize('medium')
    }
  }

  const passHandeler = (event) => {
    setPass(event.target.value);
    setErrors(passValidate(event.target.value, t))
  }

  const repeatPassHandeler = (event) => {
    setRepeatPass(event.target.value);
    setErrors(repeatPassValidate(event.target.value, pass, t))
  }

  const handleClickShowPass = () => setShowPass((show) => !show);

  const handleMouseDownPass = (event) => {
    event.preventDefault();
  }

  const focusHandler = (event) => {
    setTouched({ ...touched, [event.target.name]: true })
  }

  const emailHandeler = (event) => {
    setErrors(emailValidate(event.target.value, t))
    setEmail(event.target.value);
  }

  const smsHandler = (event) => {
    setErrors(smsValidate(event.target.value, t));
    setSms(event.target.value);
  }

  const format = (timeLeft) => {
    const date = new Date(timeLeft * 1000);
    let hh = date.getUTCHours();
    let mm = date.getUTCMinutes();
    let ss = date.getSeconds();
    if (hh < 10) { hh = "0" + hh; }
    if (mm < 10) { mm = "0" + mm; }
    if (ss < 10) { ss = "0" + ss; }
    return '00' !== hh ? hh + ":" + mm + ":" + ss : mm + ":" + ss;
  }

  const postData = () => {
    Promise.resolve(axios({
      url: baseUrl + '/api/v4/user/rpwl',
      method: "POST",
      data: { email: params.email, password: pass, smsCode: sms },
    }))
      .then((res) => {
        setResetBtn(false);
        setLoading(false);
        setAlert({ alertCheck: true, message: t("AM_resetpass"), severity: "success" })
        const timeout = setTimeout(() => {
          Navigate("/")
        }, 3000)
        return () => {
          clearTimeout(timeout)
        };
      })
      .catch(
        (error) => {
          setResetBtn(false);
          setLoading(false);
          if (error.code === "ERR_NETWORK") {
            setAlert({ alertCheck: true, message: t("networkNotconnected"), severity: "error" })
          }
          else {
            const { status } = error.response;
            setAlert({ alertCheck: true, status: status, message: "", severity: "error" })
          }
        }
      )
  }

  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    return setAlert({ alertCheck: false, message: "", severity: alert.severity })
  }

  const changeHandler = () => {
    if ((pass === "") || (errors.hasOwnProperty('pass'))) {
      setAlert({ alertCheck: true, message: t("AM_fieldPass"), severity: "error" })
    }
    else if ((repeatPass === "") || (errors.hasOwnProperty('repeatPass'))) {
      setAlert({ alertCheck: true, message: t("repeatPasswordRequierd"), severity: "error" })
    }
    else if ((email === "") || (errors.hasOwnProperty('email'))) {
      setAlert({ alertCheck: true, message: t("emailRequired"), severity: "error" })
    }
    else if (captcha === false) {
      setAlert({ alertCheck: true, message: t("AM_captcha"), severity: "error" })
    }
    else if ((!Object.keys(errors).length) && (captcha)) {
      if (pass !== repeatPass) {
        setAlert({ alertCheck: true, message: t("repeatPasswordNotMatch"), severity: "error" })
      }
      else {
        setResetBtn(true);
        setLoading(true);
        postData();
      }
      // else if (security === 0) {
      //   setAlert({ alertCheck: true, message: t("AM_NotRegister"), severity: "warning" })
      //   const timeout = setTimeout(() => {
      //     Navigate("/Register")
      //   }, 3000)
      //   return () => {
      //     clearTimeout(timeout)
      //   };
      // }
    }
  }

  // const handleCloseModal = () => {
  //   setOpen(false);
  // }

  // const postData = () => {
  //   let data = {};
  //   switch (security) {
  //     case (1):
  //       data = { email: params.email, password: pass };
  //       break;
  //   }
  //   Promise.resolve(axios({
  //     url: baseUrl + '/api/v4/user/resetPassword',
  //     method: "POST",
  //     data: data,
  //   }))
  //     .then((res) => {
  //       setResetBtn(false);
  //       setLoading(false);
  //       setAlert({ alertCheck: true, message: t("AM_resetpass"), severity: "success" })
  //       const timeout = setTimeout(() => {
  //         Navigate("/")
  //       }, 3000)
  //       return () => {
  //         clearTimeout(timeout)
  //       };
  //     })
  //     .catch(
  //       (error) => {
  //         setResetBtn(false);
  //         setLoading(false);
  //         if (error.code === "ERR_NETWORK") {
  //           setAlert({ alertCheck: true, message: t("networkNotconnected"), severity: "error" })
  //         }
  //         else {
  //           const { status } = error.response;
  //           setAlert({ alertCheck: true, status: status, message: "", severity: "error" })
  //         }
  //       }
  //     )
  // }

  return (
    <>
      <Grid sx={{
        direction: i18n.language === "fa" ? "rtl" : "ltr", width: "100%", height: "100%", justifyContent: "center",
        display: "flex", flexDirection: { xs: "column", sm: "row" }, alignContent: "center", padding: { xs: "160px 0", sm: "260px 0", md: "230px 0" }, backgroundColor: "background.main"
      }}>
        <Grid sx={{ width: { xs: "100%", sm: "50%" }, height: "100%", display: "flex", justifyContent: { xs: "center", md: "end" } }}>
          <Grid sx={{
            backgroundColor: "background.secondary", maxWidth: { xs: "95%", sm: "306px", md: "478px" }, minWidth: { xs: "95%", sm: "280px", md: "390px" }, height: '100%',
            boxShadow: "1px 3px 6px rgba(0, 0, 0, 0.25)", borderRadius: { xs: "44px", sm: "50px", md: "15%" }, padding: { xs: "5% 0", sm: "10% 0" }
          }}>
            <Grid sx={{ paddingBottom: "20px", display: "flex", alignItems: "center", justifyContent: 'center' }}>
              <Typography sx={{
                fontSize: i18n.language === "fa" ? { xs: "20px", sm: "25px", md: "30px" } : { xs: "20px", lg: "22px" },
              }}>{t("resetPass")}
              </Typography>
            </Grid>
            {/* <Grid sx={{ display: "flex", flexDirection: "row", paddingBottom: "20px", alignItems: "center", padding: "3% 12%" }}>
              <Typography sx={{
                fontSize: i18n.language === "fa" ? { xs: "16px", sm: "18px", md: "20px" } : { xs: "14px", md: "16px" },
                lineHeight: "30px", padding: "0px 2px", fontWeight: "500", color: "text.primary"
              }}>{t("user")}
              </Typography>
              <Typography sx={{
                fontSize: i18n.language === "fa" ? { xs: "16px", sm: "18px", md: "20px" } : { xs: "14px", md: "16px" },
                lineHeight: "30px", fontWeight: "500", color: "text.greenLight"
              }}>{MainEmail(params.email)}
              </Typography>
            </Grid> */}
            <Grid sx={{ display: "flex", alignItems: 'center', justifyContent: "center", padding: "3% 0" }} >
              <TextField
                className="textField"
                fullWidth
                label={t("email")}
                name="email"
                inputMode="email"
                value={email}
                size={textFieldSize}
                onChange={event => emailHandeler(event)}
                onFocus={focusHandler}
                error={errors.email && touched.email}
                helperText={errors.email && touched.email && errors.email}
                InputLabelProps={{ sx: { fontSize: { xs: "14px", md: "1rem" } } }}
                variant="outlined"
                sx={{ "& .MuiOutlinedInput-root": { "&.Mui-focused fieldset": { borderColor: "#D9D9D9" } }, "& .MuiInputBase-root fieldset": { borderRadius: "16px" }, minWidth: { xs: "70%", sm: "200px", md: "300px" }, maxWidth: { xs: "80%", sm: "230px", md: "313px" } }}
              />
            </Grid>
            <Grid sx={{ padding: "3% 12%" }}>
              <Typography sx={{
                fontSize: i18n.language === "fa" ? { xs: "14px", lg: "15px" } : { xs: "12px", md: "14px" },
                lineHeight: "30px", fontWeight: "400"
              }}>{t("textNewPass")}
              </Typography>
            </Grid>
            <Grid sx={{ display: "flex", alignItems: 'center', justifyContent: "center", padding: "0 0 3%" }} >
              <TextField
                className="textField"
                fullWidth
                type={showPass ? 'text' : 'password'}
                name="pass"
                value={pass}
                onChange={event => passHandeler(event)}
                onFocus={focusHandler}
                label={t("password")}
                InputLabelProps={{ sx: { fontSize: { xs: "14px", md: "1rem" } } }}
                variant="outlined"
                size={textFieldSize}
                error={errors.pass && touched.pass}
                helperText={errors.pass && touched.pass && errors.pass}
                sx={{
                  "& .MuiOutlinedInput-root": { "&.Mui-focused fieldset": { borderColor: "#D9D9D9" } }, "& .MuiInputBase-root fieldset": { borderRadius: "16px" },
                  minWidth: { xs: "70%", sm: "200px", md: "300px" }, maxWidth: { xs: "80%", sm: "230px", md: "313px" }
                }}
                InputProps={{
                  endAdornment:
                    <IconButton
                      aria-label="toggle pass visibility"
                      onClick={handleClickShowPass}
                      onMouseDown={handleMouseDownPass}
                      edge="end"
                    >
                      {showPass ? <VisibilityIcon sx={{ fontSize: { sm: "18px", md: "24px" } }} /> : <VisibilityOffIcon sx={{ fontSize: { sm: "18px", md: "24px" } }} />}
                    </IconButton>
                }}
              />
            </Grid>
            <Grid sx={{ display: "flex", alignItems: 'center', justifyContent: "center", padding: "3% 0" }} >
              <TextField
                className="textField"
                fullWidth
                type={showPass ? 'text' : 'password'}
                name="repeatPass"
                value={repeatPass}
                onChange={event => repeatPassHandeler(event)}
                onFocus={focusHandler}
                label={t("repeatPass")}
                InputLabelProps={{ sx: { fontSize: { xs: "14px", md: "1rem" } } }}
                variant="outlined"
                size={textFieldSize}
                error={errors.repeatPass && touched.repeatPass}
                helperText={errors.repeatPass && touched.repeatPass && errors.repeatPass}
                sx={{
                  "& .MuiOutlinedInput-root": { "&.Mui-focused fieldset": { borderColor: "#D9D9D9" } }, "& .MuiInputBase-root fieldset": { borderRadius: "16px" },
                  minWidth: { xs: "70%", sm: "200px", md: "300px" }, maxWidth: { xs: "80%", sm: "230px", md: "313px" }
                }}
                InputProps={{
                  endAdornment:
                    <IconButton
                      aria-label="toggle pass visibility"
                      onClick={handleClickShowPass}
                      onMouseDown={handleMouseDownPass}
                      edge="end"
                    >
                      {showPass ? <VisibilityIcon sx={{ fontSize: { sm: "18px", md: "24px" } }} /> : <VisibilityOffIcon sx={{ fontSize: { sm: "18px", md: "24px" } }} />}
                    </IconButton>
                }}
              />
            </Grid>
            <Grid sx={{ display: "flex", alignItems: 'center', justifyContent: "center", padding: "3% 0" }}>
              <TextField
                label={t("sms")}
                variant='outlined'
                fullWidth
                sx={{
                  "& .MuiOutlinedInput-root": { "&.Mui-focused fieldset": { borderColor: "#D9D9D9" } }, "& .MuiInputBase-root fieldset": { borderRadius: "16px" },
                  minWidth: { xs: "70%", sm: "200px", md: "300px" }, maxWidth: { xs: "80%", sm: "230px", md: "313px" }
                }}
                className="textField"
                name="sms"
                size={textFieldSize}
                value={onlyDigit(sms)}
                onChange={event => smsHandler(event)}
                onFocus={focusHandler}
                InputLabelProps={{ sx: { fontSize: { xs: "12px", md: "1rem" } } }}
                error={errors.sms && touched.sms}
                helperText={errors.sms && touched.sms && errors.sms}
                inputProps={{ maxLength: 6 }}
                InputProps={{
                  endAdornment:
                    <Button
                      // endIcon={<SmsIcon
                      //   sx={{ display: "block", marginRight: i18n.language === "fa" ? "5px" : "0px", fontSize: { xs: "10px", sm: "15px" } }} />}
                      sx={{
                        fontSize: { xs: "10px", sm: "12px", md: "14px" },
                        width: "120px", textAlign: "center", color: "text.white", marginLeft: "5px",
                        transition: "all 0.1s ease-in-out", backgroundColor: "text.greenLight", borderRadius: "10px", "&:hover": { backgroundColor: "text.greenLight" }, "& .MuiButton-endIcon": { marginLeft: 0 }
                      }}
                    >
                      {format(timeLeft)}
                    </Button>
                }}
              />
            </Grid>
            <Grid sx={{ display: "flex", justifyContent: "center", margin: "3% 0" }} className="g-recaptcha-inner">
              <ReCAPTCHA
                sitekey="6Le7R9UUAAAAAFklnUhPVV2qiSfeLXJHpDgnv6V3"
                onChange={(value) => { onChangeRechapta(value) }}
                className="g-recaptcha-login"
                hl="fa"
              />
            </Grid>
            <Grid sx={{ margin: "5% 0", display: "flex", alignItems: "center", justifyContent: "center" }}>
              <Button sx={{
                width: { xs: "50%", sm: "173px", md: "270px" }, height: { xs: "41px", md: "63px" }, background: (theme) => theme.palette.button.primary, borderRadius: "40px !important",
                "&:hover": { background: (theme) => theme.palette.button.primaryHover }, boxShadow: "1px 2px 3px rgba(0, 0, 0, 0.3)"
              }}
                onClick={changeHandler} disabled={resetBtn ? true : false}>
                <Typography sx={{ fontSize: { xs: "11px", sm: "13px", md: "20px" }, color: "text.white", lineHeight: "18px", padding: { xs: "5px", sm: "10px" } }}>
                  {resetBtn === true && loading === true ? <CircularProgress size={20} color="inherit" /> : t("resetPass")}
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid sx={{ width: { xs: "100%", sm: "50%" }, display: "flex", justifyContent: { xs: "center", md: "start" }, margin: { xs: "0px", md: "0px 30px" } }}>
          <Grid sx={{
            width: { xs: "100%", sm: "98%", md: "95%", lg: "80%" }, display: "flex", padding: { xs: "30px 0px", sm: "0px 2%", md: "20px 5%", lg: "30px" },
            flexDirection: "column", alignItems: "center"
          }}>
            <Grid sx={{ width: "100%", display: { xs: "none", sm: "flex" }, alignItems: "center", justifyContent: "center" }}>
              <Typography component={"img"} alt="signin_Icon" src={signinIcon} sx={{ maxWidth: { sm: "200px", md: "200px" }, maxHeight: { sm: "200px", md: "200px" }, minWidth: { sm: "180px", md: "200px" }, minHeight: { sm: "180px", md: "200px" } }} />
            </Grid>
            <Grid sx={{
              display: "flex", flexDirection: "column", alignItems: "start", minWidth: { xs: "95%", sm: "220px", md: "360px" }, maxWidth: { xs: "95%", sm: "328px", md: "512px" }, backgroundColor: "background.secondary", boxShadow: ' 1px 3px 6px rgba(0, 0, 0, 0.25)',
              borderRadius: { xs: "18px", sm: "25px", md: "35px" }, padding: { xs: i18n.language === "fa" ? "20px 10px 20px 20px" : "20px 20px 10px 20px", sm: i18n.language === "fa" ? "30px 20px 30px 30px" : "20px 20px 20px 10px" },
              maxHeight: { xs: "160px", sm: "174px", md: "272px" }
            }}>
              <Grid sx={{ display: "flex", flexDirection: "row", alignItems: "center", marginBottom: "15px" }}>
                <Checkbox disabled checked sx={{ '&.Mui-checked': { color: "text.greenLight" }, '& .MuiSvgIcon-root': { fontSize: "20px" } }} />
                <Typography sx={{ fontSize: i18n.language === "fa" ? { xs: "10px", sm: "11px", md: "16px" } : { xs: "10px", sm: "11px", md: "14px", lg: "16px" }, textAlign: "justify", color: "text.primary" }} >{t("roulsPart1")}</Typography>
              </Grid>
              <Grid sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                <Checkbox disabled checked sx={{ '&.Mui-checked': { color: "text.greenLight" }, '& .MuiSvgIcon-root': { fontSize: "20px" } }} />
                <Typography sx={{ fontSize: i18n.language === "fa" ? { xs: "10px", sm: "11px", md: "16px" } : { xs: "10px", sm: "11px", md: "14px", lg: "16px" }, textAlign: "justify", color: "text.primary" }}>{t("roulsPart2")}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <AlertMessage open={alert.alertCheck} status={alert.status} message={alert.message} duration={6000} close={handleCloseAlert} severity={alert.severity} page={"ChangePass"} />
      {/* <ModalPass open={open} close={handleCloseModal} data={data} type={"R"} /> */}
    </>
  );
}
export default ChangePass;